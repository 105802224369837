<template>
    <v-container fluid>
        <ApolloQuery
            :query="require('@/graphql/output-target/clientTargets.gql')"
            :variables="query_vars"
            deep
        >
            <template v-slot="{ result: { loading, error, data }, query }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="orderedItems(filterItems(data.clientTargets))"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                    :sort-by="sortBy"
                    :sort-desc="sortByDesc"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                            >
                                <manual-card :target.sync="item" />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
    </v-container>
</template>

<script>
import ManualCard from '@/views/user-dashboard/manuals/ManualCard'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'OutputTargetGrid',
    components: {
        'manual-card': ManualCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        client_id: {
            type: String,
        },
        build_type: {
            type: String,
        },
        reload: {
            type: Number,
            default: 0,
        },
        output_pool_id: {
            type: String,
        },
    },
    data: () => ({
        itemsPerPageOptions: [12, 24, 48],
        itemsPerPage: 24,
        deleteDialog: false,
        selectedTarget: {},
        sortBy: 'sort',
        sortByDesc: false,
        rando: 0,
    }),
    computed: {
        query_vars: function() {
            let out = {
                client_id: this.client_id,
                garbage: this.rando,
                output_pool_id: this.output_pool_id,
            }
            if (this.build_type !== '' && this.build_type !== 'all') {
                out.build_type = this.build_type
            }
            return out
        },
        ...mapGetters({
            user: 'authUser',
        }),
    },
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    methods: {
        canView: function(item) {
            if (item.require_client_admin === 'y') {
                if (
                    this.$store.getters.userForPortalAccess.user_type !==
                    'client_user'
                ) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        filterItems(items) {
            return items.filter(this.canView)
        },
        refreshQuery(query) {
            query.refetch()
        },
        orderedItems(items) {
            return _.orderBy(items, 'name')
        },
    },
}
</script>
