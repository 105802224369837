<template>
    <v-card>
        <v-card-title>
            <h3>Manuals</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search Manual Names..."
                clearable
                single-line
                hide-details
            />
        </v-card-title>
        <v-card-text>
            <notification-message
                v-for="item in $store.getters.userForPortalAccess
                    .unreadNotifications"
                :key="item.id"
                :message="item"
            />
            <v-tabs dark background-color="primary" v-model="selectedTab">
                <v-tab left><v-icon>mdi-earth</v-icon>Web</v-tab>
                <v-tab><v-icon>mdi-file-pdf-box</v-icon>PDF</v-tab>
                <v-tab>All Manuals</v-tab>
                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <manuals-grid
                                    build_type="web"
                                    :keywords.sync="keywords"
                                    :client_id.sync="client_id"
                                    :reload.sync="reloadGrid"
                                    :output_pool_id.sync="output_pool_id"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <manuals-grid
                                    build_type="pdf"
                                    :keywords.sync="keywords"
                                    :client_id.sync="client_id"
                                    :reload.sync="reloadGrid"
                                    :output_pool_id.sync="output_pool_id"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <manuals-grid
                                    build_type="all"
                                    :keywords.sync="keywords"
                                    :client_id.sync="client_id"
                                    :reload.sync="reloadGrid"
                                    :output_pool_id.sync="output_pool_id"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>
<script>
import ManualsGrid from '@/views/user-dashboard/manuals/ManualsGrid'
import NotificationMessage from '@/components/Layout/NotificationMessage'

export default {
    name: 'Manuals',
    components: {
        'manuals-grid': ManualsGrid,
        'notification-message': NotificationMessage,
    },
    props: ['output_pool_id'],
    data: () => ({
        keywords: '',
        outputTargetFormDialog: false,
        formOutputTarget: {},
        reloadGrid: 0,
        client_id: '',
        defaultForm: {
            id: '',
            name: '',
            img: '',
            sort: '',
            version: '',
            target_url: '',
            client: {},
            project: {},
            output_pool: {},
        },
        selectedTab: 0,
    }),

    created() {
        this.client_id = this.$store.getters.userForPortalAccess.client.id
        const preselectedTab = parseInt(this.$route.query.tab)
        if (
            !isNaN(preselectedTab) &&
            preselectedTab >= 0 &&
            preselectedTab <= 2
        ) {
            this.selectedTab = preselectedTab
        }
    },
    methods: {
        newOutputTargetForm: function() {
            this.formOutputTarget = this.defaultForm
            this.outputTargetFormDialog = true
        },
        closeOutputTargetForm: function() {
            this.outputTargetFormDialog = false
        },
        editOutputTarget: function(target) {
            this.formOutputTarget = target
            this.outputTargetFormDialog = true
        },
        reloadTheGrid: function() {
            this.reloadGrid++
        },
    },
}
</script>
