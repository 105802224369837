<template>
    <v-card class="fill-height">
        <v-container fluid>
            <v-row>
                <v-col class="d-flex justify-center">
                    <v-img
                        v-if="target.img"
                        max-width="100"
                        height="100"
                        contain
                        :src="imgSource"
                        @click="visitTarget(target)"
                    >
                    </v-img>
                    <v-img
                        v-else
                        max-width="100"
                        height="100"
                        contain
                        src="@/assets/logo_manual_makers.png"
                    >
                    </v-img>
                </v-col>
            </v-row>
        </v-container>
        <v-card-title @click="visitTarget(target)">{{
            target.name
        }}</v-card-title>
        <v-card-subtitle @click="visitTarget(target)"
            >In {{ target.client.name }}</v-card-subtitle
        >

        <v-card-actions>
            <v-btn text @click="visitTarget(target)">
                <v-icon right dark>{{ buildIcon }}</v-icon
                >View
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'OutputTargetCard',
    props: {
        target: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    computed: {
        docCount: function() {
            return 5
        },
        userCount: function() {
            return 0
            //return this.target.users.length || 0;
        },
        imgSource: function() {
            return (
                process.env.VUE_APP_API_ENDPOINT +
                'assets/target/img/' +
                this.target.img
            )
        },
        buildIcon: function() {
            if (this.target.build_type === 'web') {
                return 'mdi-earth-arrow-right'
            } else {
                return 'mdi-file-pdf-box'
            }
        },
    },
    methods: {
        visitTarget(target) {
            window.open(
                process.env.VUE_APP_API_ENDPOINT +
                    'content/' +
                    target.build_type +
                    '/' +
                    target.output_pool.id +
                    '/' +
                    target.target_url,
                '_blank'
            )
        },
    },
}
</script>
